
$(document).ready(function () {

  // Show click of the items and display the new cart icon with details
  $('.header-cart').on('click', function () {
    $('#cartgadget div.shopping-cart.BE .totalDisplay a')[0].click();
  });

  // Update new cart information
  let updateCartTimedCounter = 0;

  function updateCartTimed() {
    // Only run for 5.5 seconds
    if (updateCartTimedCounter < 25) {
      // Check for the input elements to load and move them
      return window.setInterval(function () {
        let cart_items = $('#cartgadget div.shopping-cart.BE .totalDisplay span');

        if (cart_items.length > 0) {
          $('.js--cartCount').css('display', 'flex');
          $('.cart__btn').prop("disabled", false);
          $('.js--cartCount').html(cart_items.html());

          // stop setInterval function
          window.clearInterval(updateCartTimedInstance);
        }
        updateCartTimedCounter++;
      }, 200);
    }
  }

  var updateCartTimedInstance = updateCartTimed();


  // update cart button when new item is added to cart
  setInterval(function () {
    var cart_items = $('#cartgadget div.shopping-cart.BE .totalDisplay span');

    // Show number of items only if the number exists
    if (parseInt(cart_items.html()) > 0) {
      $('.js--cartCount').css('display', 'flex');

      // Insert number of items in cart
      $('.js--cartCount').html(cart_items.html());
    } else {
      $('.js--cartCount').hide();
      $('.js--cartCount').html('');
    }

  }, 500);
});
