// convert the search gadget into bootstrap format, to allow for better styling, and automatic responsiveness
$(document).on('gadget.script.loaded', function () {
  IMUtility.pushSearchGadgetLoadedEvent();

  $w.event.subscribe('search.gadget.ready', function () {
    BE._isMobile = 1;

    $('#searchGadget .search-gadget').before($('#searchGadget .hybridTabs'));
    $('#searchGadget .hybridTabs').addClass('gadget__search-tabs').addClass('gadget__all-tabs').removeClass('hybridTabs');

    $('#searchGadget .tab.cruises').addClass('tab-link').removeClass('tab');
    $("#searchGadget .tab-link.cruises").attr("href", "/tours/kimberley-cruises");

    $('#searchGadget .tab.carHire').addClass('tab-link').removeClass('tab');
    $("#searchGadget .tab-link.carHire").attr("href", "/services/car-hire");

    $('.search-gadget span.label').wrap('<label></label>');
    $('.search-gadget span.label').addClass('gadget__search-label').removeClass('label');

    $('.search-gadget .product').addClass('col-xs-12 col-sm-2 col-md-2 gadget__search-column gadget__search-column-select');
    $('.search-gadget .date').addClass('col-xs-12 col-sm-12 col-md-4 ' + 'gadget__search-column gadget__search-column-date');

    $('.details-gadget .search-gadget .date').addClass('col-xs-12 col-sm-12 col-md-3 ' + 'gadget__search-column gadget__search-column-date');
    // $('.details-gadget .search-gadget').addClass('container');

    $('.search-gadget .period').addClass('col-xs-12 col-sm-2 col-md-2 gadget__search-column gadget__search-column-select');
    $('.search-gadget .adults').addClass('col-xs-12 col-sm-2 col-md-2 gadget__search-column gadget__search-column-select');
    $('.search-gadget .children').addClass('col-xs-12 col-sm-2 col-md-2 gadget__search-column gadget__search-column-select');
    $('.search-gadget .infants').addClass('col-xs-12 col-sm-2 col-md-2 gadget__search-column gadget__search-column-select');
    $('.search-gadget .concessions').addClass('col-xs-12 col-sm-2 col-md-2 gadget__search-column gadget__search-column-select');
    $('.search-gadget .students').addClass('col-xs-12 col-sm-2 col-md-2 gadget__search-column gadget__search-column-select');
    $('.search-gadget .observers').addClass('col-xs-12 col-sm-2 col-md-2 gadget__search-column gadget__search-column-select');
    $('.search-gadget .family').addClass('col-xs-12 col-sm-2 col-md-2 gadget__search-column gadget__search-column-select');
    $('.search-gadget .button').addClass('col-xs-12 col-sm-2 col-md-1 gadget__search-column gadget__search-column-button pull-right');

    $('#regionGadget .search-gadget .date').removeClass('col-md-4');
    $('#regionGadget .search-gadget .date').addClass('col-md-3 col-lg-2');
    $('#regionGadget .search-gadget .period').removeClass('col-md-2');
    $('#regionGadget .search-gadget .period').addClass('col-md-2 col-lg-2');
    $('#regionGadget .search-gadget .adults').removeClass('col-md-2');
    $('#regionGadget .search-gadget .adults').addClass('col-md-2 col-lg-2');
    $('#regionGadget .search-gadget .children').removeClass('col-md-2');
    $('#regionGadget .search-gadget .children').addClass('col-md-2 col-lg-2');
    $('#regionGadget .search-gadget .infants').removeClass('col-md-2');
    $('#regionGadget .search-gadget .infants').addClass('col-md-2 col-lg-2');

    $('.search-gadget .date, .search-gadget .period, .search-gadget .adults, .search-gadget .children, .search-gadget .infants').wrapInner('<div class="row"></div>');
    $('.search-gadget .concessions, .search-gadget .students, .search-gadget .observers, .search-gadget .family').wrapInner('<div class="row"></div>');
    $('.specific-prop-search').hide();
    $('.search-gadget').attr('id', 'gadget__search');
    $('.embedded-search .search-gadget .button').hide();
    $('#searchGadget .search-gadget .infants').hide();

    $('#gadget__search').append('<div class="clear"></div>');

    if ($('.search-gadget .students').length > 0) {
      $('.search-gadget').addClass('wide-row-many-columns');
    }
    $w.event.publish('search.gadget.built');
  });

  $w.event.subscribe('search.gadget.built', function () {
    $('.homepage-form').addClass('search-gadget-loaded');
    $w.event.publish('search.gadget.afterbuild');
  });

  var adjustRegionRefineToolsOnce = 0;
  $w.event.subscribe('region.refinetools.built', function () {
    // Only add these adjustments one time to avoid the same thing being applied when a user
    // changes the dates/adults etc
    if (!adjustRegionRefineToolsOnce) {
      $('.search-gadget .refineTools span.label').wrap('<label></label>');
      $('.search-gadget .refineTools span.label').addClass('gadget__search-label').removeClass('label');
      $('.search-gadget .refineTools > div label').addClass('col-xs-12 col-md-12');
      $('.search-gadget .refineTools > div .input').wrap('<div class="col-xs-12 col-md-12"></div>').css('display', 'block');

      $('.search-gadget .locationFilter').addClass('col-sm-3 col-md-3 gadget__search-column gadget__search-column-select');
      $('.search-gadget .accommTypes').addClass('col-sm-3 col-md-3 gadget__search-column gadget__search-column-select gadget__search-column-border');
      $('.search-gadget .tourTypes').addClass('col-sm-3 col-md-2 gadget__search-column gadget__search-column-select gadget__search-column-border');
      $('.search-gadget .facilities').addClass('col-sm-3 col-md-2 gadget__search-column gadget__search-column-select gadget__search-column-border');
      $('.search-gadget .maxPrice').addClass('col-sm-3 col-md-2 gadget__search-column gadget__search-column-textbox gadget__search-column-border');
      $('.search-gadget .nameFilter').addClass('col-sm-3 col-md-2 gadget__search-column gadget__search-column-textbox gadget__search-column-border');
      $('.search-gadget .sortBy').addClass('col-sm-6 col-md-4 gadget__search-column gadget__search-column-select gadget__search-column-border');
      $('.search-gadget .byWhich').addClass('col-sm-6 col-md-6');
      $('.search-gadget .byOrder').addClass('col-sm-6 col-md-6 gadget__search-column-border');

      $('.search-gadget .refineTools input').css('width', '100%');
      $('.search-gadget .refineTools select').css('width', '100%');
      $('.search-gadget .refineTools .sortBy > div').wrapInner('<div class="row"></div>');
      $('.search-gadget .refineTools > div').wrapInner('<div class="row"></div>');
      adjustRegionRefineToolsOnce = 1;
    }
  });

});
