// convert the region gadget into bootstrap format, to allow for better styling, and automatic responsiveness
$(document).on('gadget.script.loaded', function () {
  IMUtility.pushRegionGadgetLoadedEvent();
  IMUtility.pushRegionGadgetChangedEvent();
  $w.event.subscribe('region.refinetools.built', function () {

    if (($('.tabs-group').length > 0) && ($('.gadget__region-tabs').length > 0)) {
      $('.gadget__region-tabs').remove();
    }

    //$('.view-choice a').css('box-sizing', 'content-box');
    $('.view-choice a.list').addClass('hidden-xs');
    $('.view-choice a.map').addClass('hidden-xs');
    $('.view-choice').addClass('gadget__all-tabs row');
    $('.view-choice a').addClass('tab');
    $('.tabs-group').addClass('gadget__region-tabs').removeClass('tabs-group');
    $('.search-gadget .period select').change(function () {
      forceManyNightsToListView();
    });
    forceManyNightsToListView();

  });
  $w.event.subscribe('region.gadget.built', function () {

    $('.prices-grid td.date').addClass('hidden-xs');
    $('.prices-grid td.date:gt(6)').addClass('hidden-sm');
    $('.prices-grid td.date:gt(13)').addClass('hidden-md');

    $('.im-grid tr.odd, .im-grid tr.even').each(function () {
      var operatorUrl = $(this).find('td.property .name').attr('href');
      var operatorId = parseInt(operatorUrl.split('/').pop());
      $(this).find('td.price').addClass('hidden-xs');
      $(this).find('td.price:gt(6)').addClass('hidden-sm');
      $(this).find('td.price:gt(13)').addClass('hidden-md');
      $(this).find('td.property .name').wrapInner('<div class="name"></div>');
      $(this).find('td.property .name div').unwrap();
    });

    $('.im-pricebutton-label.label').removeClass('label');

  });
  $('head').append('<style> .tabs-group { display:none; } </style>');
});

function forceManyNightsToListView() {

  if (parseInt($('.search-gadget .period select').val()) >= 13) {
    var wThis = $w('div.view-choice a.list');
    var rel = wThis.attr('rel');
    $w.event.publish('region.view.change', wThis, rel);
    $('.gadget__region-tabs a').removeClass('current');
    $('.gadget__region-tabs .list').addClass('current');
  }

}


// convert the region gadget into bootstrap format, to allow for better styling, and automatic responsiveness
$(document).on('gadget.script.loaded', function () {

  $w.event.subscribe('region.gadget.ready', function () {

    // wait for prices grid to load before apply transformations

    var waitCounterRates = 0;
    $(".region-gadget.BE .prices-grid .type-group").waitUntilExists(function () {
      waitCounterRates++;
      if (waitCounterRates == 1) {
        transformPricesGrid();

        // wait for details view to load before apply transformations
        // this includes custom transformation to move the room-name and short description from prices to details tab
        // so we must also wait for the prices grid to load first

        var waitCounterDetails = 0;
        $(".region-gadget.BE .list-view .type-group").waitUntilExists(function () {
          waitCounterDetails++;
          if (waitCounterDetails == 1) {
            transformDetailsView();
          }
        });


      } //  END waitCounterRates
    });


  });



  $w.event.subscribe('region.gadget.built', function () {

    // check if prices grid has loaded before apply transformations

    if ($(".region-gadget.BE .prices-grid .type-group").length > 0) {
      transformPricesGrid();
      // check if details view has loaded before apply transformations
      // this includes custom transformation to move the room-name and short description from prices to details tab
      // so we must also check if the prices grid has loaded first
      if ($(".region-gadget.BE .list-view .type-group").length > 0) {
        transformDetailsView();
        console.log('transformDetailsView ran');
      }
    }



  });

});


function transformPricesGrid() {

  // Apply bootstrap classes
  $('.prices-grid thead').each(function () {
    $(this).find('td.date').addClass('hidden-xs hidden-sm');
    $(this).find('td.date:gt(6)').addClass('hidden-md');
    $(this).find('td.date:gt(9)').addClass('hidden-lg');
    $(this).find('td.date:gt(11)').addClass('hidden-xl');
  });

  $('.prices-grid tr.inline-header').each(function () {
    $(this).find('td.date').addClass('hidden-xs hidden-sm');
    $(this).find('td.date:gt(6)').addClass('hidden-md');
    $(this).find('td.date:gt(9)').addClass('hidden-lg');
    $(this).find('td.date:gt(11)').addClass('hidden-xl');
  });

  // add column for thumbnail and remove label
  $('.im-grid thead tr').prepend('<td class="thumb"></td>');
  $('.im-grid thead tr td.label').text('');
  $('.im-grid tbody tr.inline-header').prepend('<td class="thumb"></td>');
  $('.im-grid tbody tr.inline-header td.label').text('');

  //$('.im-grid td.total').addClass('hidden-xs');

  var gridI = 0;
  $('.im-grid tr.odd, .im-grid tr.even').each(function () {

    // bootstrap classes
    $(this).find('td.price').addClass('hidden-xs hidden-sm');
    $(this).find('td.price:gt(6)').addClass('hidden-md');
    $(this).find('td.price:gt(9)').addClass('hidden-lg');
    $(this).find('td.price:gt(11)').addClass('hidden-xl');

    // operator link on whole table cell
    var operatorUrl = $(this).find('td.property .name').attr('href');
    var operatorId = parseInt(operatorUrl.split('/').pop());

    $(this).find('td.property,td.thumb').click(function () {
      //BETouch.set('operatorid', operatorId);
      IMUtility.redirect(operatorUrl);
    }).css('cursor', 'pointer');

    // format the operator name
    $(this).find('td.property .name').wrapInner('<div class="name"></div>');
    $(this).find('td.property .name div').unwrap();

    // move thumbnail to its own column
    $(this).find('td.property').before('<td class="thumb"></td>');
    $(this).find('td.thumb').append($(this).find('div.thumb'));

    // remove empty addresses
    if ($(this).find('td.property span.address').contents == '') {
      $(this).find('td.property span.address').remove();
    }

    gridI++;

  });

}

function transformDetailsView() {
  $('.im-pricebutton-label.label').removeClass('label');
  $('.region-gadget.BE .list-view p.location').wrap('<div class="location"></div>');

  // move room name and short description to the details tab
  var deatilsTabI = 0;
  $('.im-grid tr.odd, .im-grid tr.even').each(function () {

    // move the room name to the details tab
    var roomName = $(this).find('div.room-name');
    if (roomName.size() > 0) {
      $('.region-gadget.BE .list-view .type-group .list-item:eq(' + deatilsTabI + ') .description').before(roomName);
    }
    // move the short description to the details tab
    var shortDesc = $(this).find('div.description').removeClass('description').addClass('description-short');
    if (shortDesc.size() > 0) {
      $('.region-gadget.BE .list-view .type-group .list-item:eq(' + deatilsTabI + ') .description').before(shortDesc);
    }

    deatilsTabI++;
  });
}
