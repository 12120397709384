

// this script REQUIRES imutility.js
// please ensure you include imutility.js before this script, like so:
// <jdoc:include type="impartScript" name="{shared}/v3/js/imutility.js" compress="true" />

IMUtility.detailsGadgetGridRendered = false;

// convert the details gadget into bootstrap format, to allow for better styling, and automatic responsiveness
$(document).on('gadget.script.loaded', function () {

  function adjustDetailsListing() {

    $('.im-grid td.quantity').addClass('hidden-xs');
    $('#itemGadget:not(.packages) .im-grid td.date').addClass('hidden-xs');
    $('.im-grid td.date:gt(2)').addClass('hidden-sm');
    $('.im-grid td.date:gt(3)').addClass('hidden-md');
    $('.im-grid td.date:gt(5)').addClass('hidden-lg');
    $('.im-grid td.date:gt(10)').addClass('hidden-xl');

    $('#itemGadget:not(.packages) .im-grid tbody tr').each(function () {
      $(this).find('td.price').addClass('hidden-xs');
      $(this).find('td.price:gt(2)').addClass('hidden-sm');
      $(this).find('td.price:gt(3)').addClass('hidden-md');
      $(this).find('td.price:gt(5)').addClass('hidden-lg');
      $(this).find('td.price:gt(10)').addClass('hidden-xl');

      // Add a thumb placeholder for rows with no image
      if ($(this).find('td.name div.thumb').length == 0) {
        $(this).find('td.name').prepend('<div class="thumb"><div style="background-color:#efefef;width:100%;height:100%;"></div></div>');
      }
    });
  }

  function moveThumbToNewCol() {
    // move thumb to its own column
    $('.im-grid tbody tr').each(function () {
      $(this).find('td.name').before('<td class="thumb"></td>');
      $(this).find('td.thumb').append($(this).find('div.thumb'));
    });
    // Add extra column in table header to adjust
    $('#itemGadget .priceGrid thead tr').prepend('<td class="thumb"></div>');
  }

  // Move itemGaget 'total' colum to last position in table
  function itemGadgetArrange() {

    var priceGrid = $('#itemGadget .priceGrid tbody tr');
    var priceGridHeader = $('#itemGadget .priceGrid thead tr');

    // When details gadget loads and product information items exist
    if (priceGrid.length > 0) {
      // Change the table rows
      priceGrid.each(function () {
        $(this).find('.total').appendTo($(this).closest('tr'));
      });

      // Change the table header
      priceGridHeader.each(function () {
        $(this).find('.total').appendTo($(this).closest('tr'));
      });
    }
  }

  // Copy paste info from product information into pricelist
  function detailsGadgetRoomArrange() {
    var roomListingsTop = $("#itemGadget.accom .priceGrid .name");
    var productNameList = $(".rooms__room-row.row");

    // When details gadget loads and product information items exist
    if (roomListingsTop.length > 0 && productNameList.length > 0) {
      roomListingsTop.each(function (index) {
        $(this).append($(productNameList[index]).find(".rooms__room-details-checkin").clone());
        $(this).append("<button class='itemGadget-accomodation-more'>More...</button>");
      });
    }
  }


  function accomViewMoreToggle() {
    // Move the price grid info under the room info
    $('.OperatorInfo').each(function () {
      $(this).insertAfter($(this).closest('.name').find('.rooms__room-details-checkin'));
    });

    // Handle the toggle
    if ($('#itemGadget.accom').length > 0) {
      $('.itemGadget-accomodation-more').on('click', function () {
        $(this).closest('.name').find('.OperatorInfo').slideToggle();
        if ($(this).html() == 'More...') {
          $(this).html('Less...');
        } else {
          $(this).html('More...');
        }
      });
    }
  }


  // This should run every time the pricegrid is refreshed when the user changes the date/nights etc.
  $w.event.subscribe('grid.rendered', function () {
    adjustDetailsListing();
    moveThumbToNewCol();
    itemGadgetArrange();
    detailsGadgetRoomArrange();
    accomViewMoreToggle();
  });

  $w.event.subscribe('grid.rendered', function () {

    // Please do not make changes to this file within the {shared}/v3/ folder
    // If you wish to make changes, consider copying and pasting this file into your {template}/js/ folder, and then customising

    // Do not run this event handler more than once
    if (IMUtility.detailsGadgetGridRendered) return true;
    IMUtility.detailsGadgetGridRendered = true;

    // Make modifications to the default operator details listing
    // These functions run once after the operator details page loads
    // and when the dropdown options change eg ('Adults' changed from 2 to 4)
    // the layout should be rebuilt for the details gadget price grid

    // adjustDetailsListing();
  });

  //   $w.event.subscribe('grid.rendered', function () {


  //     /**/
  //     function waitForHoverDescription() {

  //       if (typeof BE.gadget.search.options == 'undefined') return false;

  //       return window.setInterval(function () {
  //         // Do not wait of description hover is turned off
  //         if (BE.gadget.search.options['descriptionHover'] == false) {
  //           clearInterval(hoverDescriptionInterval);
  //           arrangeRowData();
  //         }

  //         // Handle hover inline differently to normal hover
  //         var hoverInline = false;
  //         var waitForElement = '.im-grid table tbody tr:first-child td.name a.has-hover';
  //         if (BE.gadget.search.options['showHoverInline'] == true) {
  //           waitForElement = '.im-grid table tbody tr:first-child td.name a.more';
  //           hoverInline = true;
  //         }

  //         // keep running this function until the hover description has been added
  //         if ($(waitForElement).length == 0) {
  //           return;
  //         }

  //         if (hoverInline) {
  //           if (IMUtility.debug) console.log('more link exists'); // hover inline
  //           // If "View more info" is in the actionbar, remove the gadget's more link
  //           if ($('.im-grid tbody tr .viewmore').length > 0) {
  //             $('.im-grid td.name a.more').remove();
  //           } else {
  //             // If no "view more info" link, add one plus a click action
  //             $('.im-grid tbody tr').each(function () {
  //               if ($(this).find('.OperatorItemContent').text().length > 0) {
  //                 $(this).find('.actionbar').append('<div class="viewmore">' + IMUtility.viewMoreText + '</div>');
  //                 $(this).find('.actionbar .viewmore').css('cursor', 'pointer');
  //               }
  //             });
  //             $('.im-grid .viewmore').click(function () {
  //               $(this).parent().parent().parent().find('.OperatorInfo').slideToggle();
  //             });
  //           }
  //         } else {
  //           if (IMUtility.debug) console.log('has hover exists'); // hover
  //           // If "View more info" is in the actionbar, remove the hover link class
  //           if ($('.im-grid tbody tr .viewmore').length > 0) {
  //             $('.im-grid tbody tr td.name div.roomname a.has-hover').contents().unwrap().wrap('<a></a>');
  //           } else {
  //             // If no "view more info" link, show the hover link as is
  //             $(this).find('td.name div.roomname a.has-hover').css('display', 'block');
  //           }
  //         }

  //         clearInterval(hoverDescriptionInterval);
  //         arrangeRowData();


  //       }, 500);
  //     }
  //     var hoverDescriptionInterval = waitForHoverDescription();



  //     function arrangeRowData() {


  //       // Insert row details into named containers..
  //       $('.im-grid tbody tr').each(function () {
  //         if (IMUtility.debug) console.log('Next Row: ' + $(this).find('td.name a').text());
  //         $(this).find('.operatorname').text($('.operator-title').text());
  //         $(this).find('td.name a').appendTo($(this).find('.roomname'));
  //         $(this).find('td.name .specials').appendTo($(this).find('.actionbar'));
  //       });

  //       // Add "View more info" text to all accom rows.
  //       // Click action is handled by the rooms template layout
  //       /*
  // $('.accom .im-grid tbody tr').each(function() {
  //   if (IMUtility.debug) console.log('ADD VIEW MORE LINK TO: '+ $(this).find('.roomname').text() );
  //   $(this).find('.actionbar').append('<div class="viewmore">'+IMUtility.viewMoreText+' 2</div>');
  // });
  // */
  //     }



});
